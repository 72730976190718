@tailwind base;

@layer base {
  :root {
    --amber-100: 60, 33%, 97%;
    --amber-300: 70, 28%, 87%;
    --amber-500: 69, 10%, 55%;
    --amber-600: 70, 15%, 30%;
    --amber-900: 75, 14%, 16%;

    --blue-0: 218, 85%, 97%;
    --blue-50: 229, 100%, 98%;
    --blue-500: 216, 100%, 60%;
    --blue-600: 216, 100%, 50%;
    --blue-700: 218, 100%, 46%;
    --pink-0: theme('colors.pink.50');
    --letter-spacing-tighter: -0.02em;
    --letter-spacing-tight: -0.01em;
    --letter-spacing-normal: 0;
    --letter-spacing-wide: 0.02em;
    --letter-spacing-wider: 0.03em;
    --letter-spacing-widest: 0.1em;
    --font-size-xs: 0.813rem;
    --font-size-sm: 0.875rem;
    --font-size-base: 0.938rem;
    --font-size-md: 1.063rem;
    --font-size-xl: 1.188rem;
    --font-size-2xl: 1.313rem;
    --font-size-3xl: 1.953rem;
    --font-size-4xl: 2.441rem;
    --font-size-5xl: 3.052rem;
  }
}
